import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

export default function configureStore(initialState) {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
}

// function loadFromLocalStorage(state) {
//   try{
//     const serializedState = localStorage.getItem('state');
//     if (serializedState === null) return undefined
//     return JSON.parse(serializedState);
//   } catch (e) {
//     console.log(e);
//     return undefined;
//   }
// }
//
// const persistedState = loadFromLocalStorage();