import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import "./nav.css";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(1) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    height: "100%"
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  button: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3f51b5',
    },
    '&:visited': {
      color: '#fff',
      backgroundColor: '#3f51b5',
    }
  }
}));

export function Nav({ auth }) {
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  // const openProfile = Boolean(profileAnchorEl);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const openUser = Boolean(userAnchorEl);
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, login, logout, userHasScopes } = auth;

  const profileOptions = [
    {
      name: 'Home',
      roles: "",
      link: '/'
    }
  ];

  const centerOptions = [
    {
      name: 'Center Details',
      roles: ["read:center"],
      link: '/center'
    },
    {
      name: 'Registration',
      roles: ["read:center"],
      link: '/registration'
    },
  ];

  const registrationsOptions = [
    {
      name: 'Registration Details',
      roles: ["read:registrations"],
      link: '/filedownload'
    },
  ];

  const centerSummaryOptions = [
    {
      name: 'Admin Report - Centers Summary',
      roles: ["read:centersummary"],
      link: '/paybycenter'
    },
  ];

  const classTeacherOptions = [
    {
      name: 'Batch7 Scheduling',
      roles: ["read:classteacher"],
      link: '/examschedule'
    },
    {
      name: 'FR-Batch7 Scheduling',
      roles: ["read:classteacher"],
      link: '/fr-examschedule'
    }
  ];

  const admin = [
    {
      name: 'All Centers',
      roles: ["read:admin"],
      link: '/allenrolled'
    },
  ];

  const handleProfileChange = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleUserChange = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    handleUserClose();
    handleProfileClose();
    history.push(option.link);
  };

  function renderUserMenu() {
    let menu = null;
    if (isAuthenticated()) {
      menu = <Menu
        id="user-menu"
        anchorEl={userAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openUser}
        onClose={handleUserClose}
      >
        {(profileOptions.map((option, index) => (
          <MenuItem
            key={option.name}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.name}
          </MenuItem>
        )))}

        {userHasScopes(["read:center"]) && (centerOptions.map((option, index) => (
          <MenuItem
            key={option.name}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.name}
          </MenuItem>
        )))}

        {userHasScopes(["read:registrations"]) && (registrationsOptions.map((option, index) => (
          <MenuItem
            key={option.name}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.name}
          </MenuItem>
        )))}

        {userHasScopes(["read:centersummary"]) && (centerSummaryOptions.map((option, index) => (
          <MenuItem
            key={option.name}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.name}
          </MenuItem>
        )))}

        {userHasScopes(["read:classteacher"]) && (classTeacherOptions.map((option, index) => (
          <MenuItem
            key={option.name}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.name}
          </MenuItem>
        )))}

        {userHasScopes(["read:admin"]) && (admin.map((option, index) => (
          <MenuItem
            key={option.name}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.name}
          </MenuItem>
        )))}

        <MenuItem
          onClick={logout}
        >
          Logout
        </MenuItem>

      </Menu>;
    }
    return (
      menu
    );
  }

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <a href="https://www.sgsgitafoundation.org">
              <img edge="start" src={require("./images/logo.png")} width={"40vh"} height={"40vh"} alt="Logo" />
            </a>

            <Typography variant="h6" color="inherit" className="ml-3" noWrap>
              SGS Gita Foundation
            </Typography>

            <div className={classes.grow} />

            <div>
              <IconButton
                aria-label="user-menu"
                aria-controls="user-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleUserChange}
              >
                <MenuIcon />
              </IconButton>
              {renderUserMenu()}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
  // }
}

export default Nav;
