import React, { useState } from "react";
import { Button, Nav, Card, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
// import { getEmail } from "../../redux/actions/commonActions";
import Spinner from "../common/Spinner";


export function HomePage({
  // getEmail,
  // email,
  apiCallsInProgress,
  ...props
}) {

    const [loading, setLoading] = useState(false);
    const { isAuthenticated, login, userHasScopes } = props.auth;


    function centers() {
        return (
            <div className="centerTable">
                <div className="mt-1">
                    <h1><b>Welcome to SGS Gita Foundation Portal!</b></h1>
                </div>
            </div>
        );
    }

  function defaultMsg() {
    return (
      <div className="centerTable">
        <div className="mt-1">
            <h1><b>Welcome to SGS Gita Foundation Portal!</b></h1>
        </div>
        <br /><br />
        <div className="mt-1">
          <p>
            Please login with your registered email id to access the portal.
          </p>
          <p style={{ fontSize: "1.0rem", color: "blue" }}>
            {" "}
            (Registered email is the one you provided while filling the
            registration form)
          </p>
          <Button onClick={login}>Log In</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="home">
      <div>
        {loading ?
            <>
            <Spinner/>
            </>
            :
            (() => {
              if (isAuthenticated())
                return centers();
              if (!isAuthenticated()) return defaultMsg()
              else return (<Spinner/>);
            })()
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    // enrolledStudentsData: state.enrolledStudentsData,
    // email: state.email,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {
  // getEmail,
  // loadEnrolStudents
};

// export default HomePage;
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
