import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./redux/configureStore";

//Added CSS import here
import "material-design-lite/dist/material.min.css";
import "material-design-lite/dist/material.purple-indigo.min.css";
import "material-design-lite/material";
import "./common.css";
import "./spectrum.css";
import "./index.css";
import "./components/Home/home.css";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
      <ReduxProvider store={store}>
          <Router >
              <Route component={App} />
          </Router>
          <ToastContainer />
      </ReduxProvider>,
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();