import auth0 from "auth0-js";

const REDIRECT_ON_LOGIN = "redirect_on_login";

// Stored outside class since private
// eslint-disable-next-line
let _idToken = null;
let _accessToken = null;
let _scopes = null;
let _roles = null;
let _expiresAt = null;

export default class Auth {
  constructor(history) {
    this.history = history;
    this.userProfile = null;
    this.requestedScopes = "openid profile email read:states read:admin read:center read:registrations read:centersummary read:classteacher";
    this.requestedRoles = "openid profile email roles";
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      responseType: "token id_token",
      auto_login: false,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      scope: this.requestedScopes,
      role: this.requestedRoles,
      mode: "signUp"
    });
  }

  login = () => {
    //Cookies.set(REDIRECT_ON_LOGIN,JSON.stringify(this.history.location));
    // sessionStorage.setItem(REDIRECT_ON_LOGIN,
    //     JSON.stringify(this.history.location)
    // );
    this.history && localStorage.setItem(
      REDIRECT_ON_LOGIN,
      JSON.stringify(this.history.location)
    );
    this.auth0.authorize({mode: "login"});
  };

  signup = () => {
    //Cookies.set(REDIRECT_ON_LOGIN,JSON.stringify(this.history.location));
    // sessionStorage.setItem(REDIRECT_ON_LOGIN,
    //     JSON.stringify(this.history.location)
    // );
    this.history && localStorage.setItem(
      REDIRECT_ON_LOGIN,
      JSON.stringify(this.history.location)
    );
    this.auth0.authorize({mode: "signUp"});
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        const redirectLocation =
          localStorage.getItem(REDIRECT_ON_LOGIN) === "undefined"
            ? "/"
            : JSON.parse(localStorage.getItem(REDIRECT_ON_LOGIN));
        this.history.push(redirectLocation);
      } else if (err) {
        this.history.push("/");
        alert(`Error: ${err.error}. Check the console for further details.`);
        console.log(err);
      }
      //Cookies.remove(REDIRECT_ON_LOGIN);
       localStorage.removeItem(REDIRECT_ON_LOGIN);
    });
  };

  setSession = authResult => {
    // console.log(authResult);
    // set the time that the access token will expire
    _expiresAt = authResult.expiresIn * 10 + new Date().getTime();

    // If there is a value on the `scope` param from the authResult,
    // use it to set scopes in the session for the user. Otherwise
    // use the scopes as requested. If no scopes were requested,
    // set it to nothing
    _scopes = authResult.scope || this.requestedScopes || "";
    _roles = authResult.role || this.requestedRoles || "";
    _accessToken = authResult.accessToken;
    _idToken = authResult.idToken;
    this.scheduleTokenRenewal();
  };

  isAuthenticated() {
    return new Date().getTime() < _expiresAt;
  }

  logout = () => {
    this.auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL
    });
  };

  startEnrollment(userEmail) {
    this.auth0.changePassword({
      connection: 'Username-Password-Authentication',
      email:   userEmail
    }, function (err, resp) {
      if(err){
        console.log(err.message);
      }else{
        console.log(resp);
      }
    });
  }

  getAccessToken = () => {
    if (!_accessToken) {
      throw new Error("No access token found.");
    }
    return _accessToken;
  };

  getProfile = cb => {
    if (this.userProfile) return cb(this.userProfile);
    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(profile, err);
    });
  };

  updateUserStatus = () => {
    try {
      // Get an updated token for calling management api
      this.auth0.checkSession (
        {
          audience: `https://${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/`,
          scope: 'read:current_user read:current_user_metadata update:current_user_metadata'
        }, function(err, result) {
          if (err) {
            console.log(err);
          } else {
            // create an instance of management api
            var auth0Manage = new auth0.Management({
              domain: process.env.REACT_APP_AUTH0_DOMAIN,
              token: result.accessToken
            });
            // get complete user object including user_metadata
            auth0Manage.getUser(result.idTokenPayload.sub, (err, res) => {
              if (err) {
                console.log(err);
              } else {
                if (res.user_metadata.new_user === true) {
                  // update the new_user value to false for the logged in user
                  var updated_meta_data = res.user_metadata;
                  updated_meta_data.new_user = false;
                  // use the patch operation to update the user meta data using the maanagement api
                  auth0Manage.patchUserMetadata(result.idTokenPayload.sub, updated_meta_data, (err, res) => {
                      console.log(" Patch User Metadata finished ... ", err, res);
                  });
                }
              }
            });
          }
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  userHasScopes(scopes) {
    const grantedScopes = (_scopes || "").split(" ");
    return scopes.every(scope => grantedScopes.includes(scope));
  }

  userHasRoles(roles) {
    const grantedRoles = (_roles || "").split(" ");
    return roles.every(role => grantedRoles.includes(role));
  }

  renewToken(cb) {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        if (err.error === "unauthorized") {
          this.auth0.logout({
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL
          });
          // store.addNotification({
          //   title: "Check your email",
          //   message: "Verification link has been sent to your email. " +
          //       "Please click on the link in that email to confirm your Email id",
          //   type: "default",
          //   insert: "top",
          //   container: "center",
          //   animationIn: ["animated", "fadeIn"],
          //   animationOut: ["animated", "fadeOut"],
          //   dismiss: {
          //     duration: 0,
          //     showIcon: true
          //   }
          // });
        }
        console.log(`Error: ${err.error} - ${err.error_description}.`);
      } else {
        this.setSession(result);
      }
      if (cb) cb(err, result);
    });
  }

  scheduleTokenRenewal() {
    const delay = _expiresAt - Date.now();
    if (delay > 0) setTimeout(() => this.renewToken(), delay);
  }
}
