import React, { Component } from "react";
import { Route } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import Auth from "./Auth/Auth";
import Nav from "./Nav";
// import Callback from "./Callback";

import PrivateRoute from "./PrivateRoute";
import AuthContext from "./AuthContext";
// import FileDownload from "./FileDownload";
// import EnrollCenterPage from "./components/Center/EnrollCenterPage";
// import AllEnrollPage from "./components/Admin/AllEnrollPage";
// import GitaRegistrationPage from "./components/Registration/GitaRegistrationPage";
// import PaymentByCenterPage from "./components/Admin/PaymentByCenterPage";
// import ExamSchedulingPage from "./components/Center/ExamSchedulingPage";
// import FRExamSchedulingPage from "./components/Center/FRExamSchedulingPage";
// import RegThankYouPage from "./components/Registration/NewRegistration/ThankYouPage";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    backgroundColor: 'lightgrey',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '4%',
    marginBottom: '4%',
    [theme.breakpoints.up('sm')]: {
      // width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }
}));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: new Auth(this.props.history),
      tokenRenewalComplete: false,
    };
  }

  componentDidMount() {
    this.state.auth.renewToken(() =>
      this.setState({ tokenRenewalComplete: true })
    );
  }

  render() {
    const { auth } = this.state;
    const classes = this.props.classes;
    // Show loading message until the token renewal check is completed.
    if (!this.state.tokenRenewalComplete) return "Loading...";
    return (
      <main className={classes.backgroundColor}>
        <AuthContext.Provider value={auth}>
          <Nav auth={auth} />
          <div className={classes.layout}>
            {/*<Route*/}
              {/*path="/callback"*/}
              {/*render={(props) => <Callback auth={auth} {...props} />}*/}
            {/*/>*/}

            {/*<PrivateRoute path="/student" component={() => <StudentPage />} />*/}
            {/*<PrivateRoute path="/liability" component={Liability} />*/}
            {/*<Route path="/checkout" component={Checkout} />*/}
            {/*/!*<PrivateRoute exact path="/payment" component={BrainTreeDropIn} />*!/*/}
            {/*<PrivateRoute path="/addstudent" component={AddStudent} />*/}
            {/*<PrivateRoute path="/orderhistory" component={OrderHistory} />*/}
            {/*<PrivateRoute path="/thankyou" component={ThankYou} />*/}
            {/*<PrivateRoute path="/failure" component={Failure} />*/}
            {/*<PrivateRoute path="/payments" component={PaymentsPage} />*/}
            {/*<PrivateRoute exact path="/account" component={UserProfilePage} />*/}

            {/*<PrivateRoute*/}
              {/*path="/center"*/}
              {/*component={EnrollCenterPage}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:center"]}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
              {/*path="/examschedule"*/}
              {/*component={ExamSchedulingPage}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:classteacher"]}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
              {/*path="/fr-examschedule"*/}
              {/*component={FRExamSchedulingPage}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:classteacher"]}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
              {/*path="/signup"*/}
              {/*component={GitaRegistrationPage}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:center"]}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
              {/*path="/filedownload"*/}
              {/*component={FileDownload}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:registrations"]}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
              {/*path="/allenrolled"*/}
              {/*component={AllEnrollPage}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:admin"]}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
              {/*path="/paybycenter"*/}
              {/*component={PaymentByCenterPage}*/}
              {/*auth={auth}*/}
              {/*scopes={["read:admin"]}*/}
            {/*/>*/}

            <Route
              exact
              path="/"
              render={(props) => <HomePage auth={auth} {...props} />}
            />
            {/*<Route path="/regthankyou" component={RegThankYouPage} />*/}
            {/*<Route path="/registration" component={RegistrationPage} />*/}
            {/*<Route path="/enty" render={(props) => <PasswordReqThankYouPage auth={auth} {...props} />} />*/}
          </div>
        </AuthContext.Provider>
      </main>
    );
  }
}

export default () => {
  const classes = useStyles();
  return (
    <App classes={classes} />
  )
};
